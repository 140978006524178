import React, {useState} from 'react'
import { useNavigate } from "react-router-dom"
import {
  Box,
  TextField,
  InputAdornment,
  Link,
  Button,
  IconButton,
} from '@mui/material';

import {
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";

import { useMiddletier } from '../common/middletier'
import { useStoreDispatch, APP_STORE_ACTION } from '../common/storeContext'

const LoginForm = (props) => {
  const appDispatch = useStoreDispatch()
  const navigate = useNavigate()
  const { mutation } = useMiddletier()

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickLogin = () => {
    const method = 'login'
    mutation([
      {
        method,
        params: [
          { code: 'tenant', graphqlType: 'String', required: true, value: 'master' },
          { code: 'username', graphqlType: 'String', required: true, value: '' },
          { code: 'password', graphqlType: 'String', required: true, value: '' }
        ],
        attributes: []
      }
    ])
      .then(({ data }) => {
        if (data[method]) {
          appDispatch({ type: APP_STORE_ACTION.LOGIN, payload: { ...data[method] } })
          navigate('/')
        }
      })
      .catch((error) => {
        console.error('error: ', error)
        if (error.graphQLErrors) {
          error.graphQLErrors.forEach((_error) => {
            if (_error.extensions.code === 'UNAUTHENTICATED') {
              appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            }
          })
        }
      })
  }

  return (
    <Box sx={{ display: 'flex', width: '380px', margin: 'auto', flexDirection: 'column', gap: '5px' }}>
      <TextField id="username" label="Email" />
      <TextField id="password"
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>,
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Link href="#" variant="body2">Forget password?</Link>
        <div style={{ flex: 1 }}></div>
        <Button variant="contained" onClick={handleClickLogin}>Login</Button>
      </Box>
    </Box>
  )
}

export default LoginForm