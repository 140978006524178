import React from 'react'
import { useNavigate } from "react-router-dom"
import { useStoreDispatch, APP_STORE_ACTION } from '../common/storeContext'

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from '@mui/material';

import {
  Menu as MenuIcon,
} from "@mui/icons-material";

const AppHeader = (props) => {
  const appDispatch = useStoreDispatch()
  const navigate = useNavigate();

  const handleClickLogout = () => {
    appDispatch({ type: APP_STORE_ACTION.LOGOUT })
    navigate('/login')
  }

  const handleClickNav = () => {
    appDispatch({ type: APP_STORE_ACTION.TOGGLE_NAV_BAR })
  }

  return (
    <AppBar position="static">
      <Toolbar variant="dense">
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleClickNav}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>Master</Typography>
        <Button color="inherit" onClick={handleClickLogout}>Logout</Button>
      </Toolbar>
    </AppBar>
  )
}

export default AppHeader