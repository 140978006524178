export const FORM_MODE = {
  NEW: 'NEW',
  EDIT: 'EDIT',
  READ_ONLY: 'READ_ONLY'
}

export const DATA_TYPE = {
  'STRING': 'STRING',
  'UUID': 'UUID',
  'TEXT': 'TEXT',
  'BOOLEAN': 'BOOLEAN',
  'INTEGER': 'INTEGER',
  'SEQUENCE': 'SEQUENCE',
  'DECIMAL': 'DECIMAL',
  'MONEY': 'MONEY',
  'DATETIME': 'DATETIME',
  'DATE': 'DATE',
  'LIST_GROUP': 'LIST_GROUP',
  'MODEL': 'MODEL',
}

export const DATA_TYPE_TO_GRAPHQL = {
  'STRING': 'String',
  'UUID': 'String',
  'TEXT': 'String',
  'BOOLEAN': 'Boolean',
  'INTEGER': 'Int',
  'SEQUENCE': 'Int',
  'DECIMAL': 'Float',
  'MONEY': 'Float',
  'DATETIME': 'DateTime',
  'DATE': 'Date',
  'LIST_GROUP': 'String',
  'MODEL': 'String',
}