import React from 'react'
import { useNavigate } from "react-router-dom"
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';

import * as MuiIcons from "@mui/icons-material"

import { useStoreDispatch, useStoreState, APP_STORE_ACTION } from '../common/storeContext'

const AppNav = (props) => {
  const appDispatch = useStoreDispatch()
  const appState = useStoreState()
  const navigate = useNavigate();

  const { sx, items } = props

  const handleClickNav = (item) => {
    appDispatch({ type: APP_STORE_ACTION.SET_SELECTED_NAV_ITEM, payload: item.code })
    navigate(`/${item.code === '/' ? '' : item.code}`)
  }

  return (
    <Box sx={{ ...sx, bgcolor: 'background.paper', overflow: 'hidden' }}>
      <List dense={true} aria-label="main nav">
        {items.map((item) => {
          const DefaultIcon = MuiIcons[item.icon]
          return (
            <ListItemButton
              key={item.code}
              selected={appState.selectedNavitem === item.code}
              onClick={(event) => { handleClickNav(item) }}
            >
              <Tooltip title={item.name}>
                <ListItemIcon>
                  {DefaultIcon ? <DefaultIcon /> : <></>}
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={item.name} />
            </ListItemButton>
          )
        })}
      </List>
    </Box>
  )
}

export default AppNav