import React, { useReducer, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom"

import {
  Box,
  Typography,
  IconButton,
  Paper,
} from '@mui/material';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
} from "@mui/icons-material";

import { useStoreState } from '../common/storeContext'

const FormEditorComponent = ({ items }) => {
  if (items.properties && items.properties.length) {
    const children = items.properties.map((property, index) => <FormEditorComponent key={property.key ?? property.code} items={property} />)
    return (
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '5px', border: '1px solid rgb(0,0,0,0.54)' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ backgroundColor: 'rgb(0,0,0,0.54)', color: '#fff', padding: '2px 10px' }}>
            <Typography variant="caption">{items.type}</Typography>
          </Box>
          <Box sx={{ flex: 1 }}></Box>
          {items.type === 'FORM' ?
            <></>
            :
            <>
              <IconButton aria-label="delete" size="small">
                <AddIcon fontSize="inherit" />
              </IconButton>
              <IconButton aria-label="delete" size="small">
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </>
          }
        </Box>
        <Box sx={{ display: 'flex', ...(items.type === 'FORM' && { flex: 1 }), flexDirection: items.type === 'ROW' ? 'row' : 'column', padding: '5px', gap: '5px' }}>{children}</Box>
      </Box>
    )
  }
  else {
    return (
      <Box
        sx={{ flex: 1 }}
      >{items.key ?? items.code}</Box>
    )
  }
}

const updateObjectByCode = ({ code, newItem, items }) => {
  if (items.code === code) {
    return { code, ...newItem }
  }
  else {
    if (items.properties) {
      const _properties = items.properties.map((property) => updateObjectByCode({ code, newItem, items: property }))
      return { ...items, properties: _properties }
    }
    else {
      return { ...items }
    }
  }
}

const FORM_EDITOR_ACTION = {
  RESET: 'reset',
  SET_MODEL: 'setModel',
  SET_SELECTED_FORM: 'setSelectedForm',
  UPDATE_FORM: 'updateForm',
  UPDATE_EDITOR_COMPONENT_STATE: 'updateEditorComponentState'
}

const initialFormEditorData = {
  model: {},
  attributes: [],
  selectedForm: '',
  form: {}
}

const formEditorReducer = (state, action) => {
  switch (action.type) {
    case FORM_EDITOR_ACTION.RESET: {
      return { ...initialFormEditorData };
    }

    case FORM_EDITOR_ACTION.SET_MODEL: {
      const _model = { ...action.payload }
      const _attributes = [..._model.attributes]
      const _form = _model.forms.find((form) => form.code === 'DEFAULT')

      return { ...state, model: _model, attributes: _attributes, selectedForm: 'DEFAULT', form: _form };
    }

    case FORM_EDITOR_ACTION.SET_SELECTED_FORM: {
      const _selectedForm = action.payload
      const _form = state.model.forms.find((form) => form.code === _selectedForm)
      return { ...state, selectedForm: _selectedForm, form: { ..._form } };
    }

    case FORM_EDITOR_ACTION.UPDATE_FORM: {
      const _form = { ...action.payload }
      const _index = state.model.forms.findIndex((form) => form.code === state.selectedForm)

      const _model = { ...state.model }
      _model.forms.splice(_index, 1, _form)

      return { ...state, model: _model, form: { ..._form } };
    }

    case FORM_EDITOR_ACTION.UPDATE_EDITOR_COMPONENT_STATE: {
      const { item, type } = action.payload
      const form = { ...state.form }
      const _index = state.model.forms.findIndex((form) => form.code === state.selectedForm)

      const _form = updateObjectByCode({ code: item.code, newItem: { ...item, focused: type === 'FOCUSED' }, items: form })

      const _model = { ...state.model }
      _model.forms.splice(_index, 1, _form)

      return { ...state, model: _model, form: { ..._form } };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const DefaultFormEditor = (props) => {
  const appState = useStoreState()
  const navigate = useNavigate()
  const params = useParams()

  const [formEditorState, formEditorDispatch] = useReducer(formEditorReducer, initialFormEditorData)

  useEffect(() => {
    if (appState.loggedIn === false) {
      navigate('/login')
    }
  }, [appState, navigate])

  useEffect(() => {
    const { model } = params
    const _model = appState.schema[model]

    if (_model) {
      formEditorDispatch({ type: FORM_EDITOR_ACTION.SET_MODEL, payload: _model })
      formEditorDispatch({ type: FORM_EDITOR_ACTION.SET_SELECTED_FORM, payload: 'DEFAULT' })
    }
    else {
      formEditorDispatch({ type: FORM_EDITOR_ACTION.RESET })
    }
  }, [appState, params])

  return (
    <Box sx={{ display: 'flex', flex: 1, margin: '10px', gap: '5px' }}>
      <Paper sx={{ display: 'flex', flex: 1, flexDirection: 'column', padding: '10px' }}>
        <FormEditorComponent
          items={formEditorState.form}
        // onMouseEnter={onMouseEnterEditorComponent} 
        // onMouseLeave={onMouseLeaveEditorComponent} 
        />
      </Paper>
      <Box sx={{ display: 'flex', width: '300px', flexDirection: 'column', gap: '5px', padding: '10px' }}>
        <Typography variant='h6'>Attributes</Typography>
        <Box sx={{ backgroundColor: '#f5f5f5', flex: 1, overflow: 'hidden', position: 'relative' }}>
          <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', flexDirection: 'column', gap: '3px', overflow: 'auto', margin: '10px' }}>
            {formEditorState.attributes.map((attr) => {
              return (
                <Box
                  key={attr.code}
                  sx={{ padding: '8px 16px', lineHeight: '24px', display: 'flex', gap: '5px', alignItems: 'center', border: '1px solid rgb(0,0,0,0.12)', backgroundColor: '#fff' }}
                  draggable="true"
                  onDrag={() => { console.log('onDrag') }}
                >
                  <DragIndicatorIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                  <Typography variant='body1'>{attr.name}</Typography>
                  <Typography variant='body2'>{attr.type}</Typography>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default DefaultFormEditor