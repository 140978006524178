import './App.css';
import React from 'react'
import { Routes, Route } from "react-router-dom"

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@mui/material';

import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from './common/storeContext'

import AppHeader  from './components/appHeader'
import AppNav from './components/appNav'
import LoginForm from './forms/login'
import DefaultForm from './forms/default'
import DefaultFormEditor from './forms/formEditor'

import DefaultView from './views/default'
import Dashboard from './views/dashboard'

const Content = (props) => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard {...props} />} />
      <Route path="/login" element={<LoginForm {...props} />} />
      <Route path="/:model/new" element={<DefaultForm mode={"new"} {...props} />} />
      <Route path="/:model/-" element={<DefaultFormEditor mode={"new"} {...props} />} />
      <Route path="/:model/:modelId" element={<DefaultForm {...props} />} />
      <Route path="/:model" element={<DefaultView {...props} />} />
    </Routes>
  )
}

const App = () => {
  const appState = useStoreState()
  const appDispatch = useStoreDispatch()

  const handleCloseAppAlert = () => {
    if (appState.alert.type && appState.alert.type === APP_STORE_ACTION.UNAUTHENTICATED) {
      appDispatch({ type: APP_STORE_ACTION.LOGOUT })
    }
    else {
      appDispatch({ type: APP_STORE_ACTION.SHOW_ALERT, payload: false })
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
      {appState.loggedIn ?
        <>
          <Dialog
            open={appState.alert.open ?? false}
            onClose={handleCloseAppAlert}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {appState.alert.title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{appState.alert.description}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAppAlert} autoFocus>Close</Button>
            </DialogActions>
          </Dialog>
          <AppHeader />
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, backgroundColor: '#f5f5f5' }}>
            <AppNav sx={{ width: appState.showNav === true ? '256px' : '60px' }} items={appState.navItems} />
            <Content sx={{ display: 'flex', flexDirection: 'row', flex: 1, margin: '5px', padding: '5px' }} />
          </Box>
        </>
        :
        <Content sx={{ display: 'flex', flexDirection: 'row', flex: 1 }} />
      }
    </Box>
  );
}

export default App;
