import React from 'react'
import { Controller, useFormContext } from "react-hook-form"

import {
  Box,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material'

import { FORM_MODE } from '../common/constants'
import CustomFormComponents from '../forms/components'

const FormComponent = ({ items, mode }) => {
  const { control } = useFormContext()

  if (typeof (items) === 'object' && items.length) {
    const children = items.map((property) => {
      return <FormComponent key={property.key ?? property.code} items={property} mode={mode} />
    })

    return <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, margin: '5px', padding: '5px', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, overflow: 'auto' }}>{children}</Box>
  }
  else {
    const { type, properties } = items

    if (type === 'SECTION') {
      const children = properties.map((property) => {
        return <FormComponent key={property.key ?? property.code} items={property} mode={mode} />
      })

      return <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '10px' }}>{children}</Box>
    }
    else if (type === 'ROW') {
      const children = properties.map((property) => {
        return <FormComponent key={property.key ?? property.code} items={property} mode={mode} />
      })

      return <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>{children}</Box>
    }
    else {
      let disabled = false

      if (items.isSystem === true || type === 'SEQUENCE' || (mode === FORM_MODE.EDIT && items.isKey === true) || (mode === FORM_MODE.EDIT && items.readOnly === true)) {
        disabled = true
      }

      const _props = { ...items }
      Object.keys(_props).forEach((key) => {
        if (['isKey', 'isLabel', 'isSystem', 'type', 'key', 'defaultValue'].indexOf(key) !== -1) {
          delete _props[key]
        }
      })

      // console.log('_props: ', items.key, errors)
      if (type === 'STRING') {
        return (
          <Controller
            control={control}
            name={items.key}
            rules={{ required: items.required &&  items.required === true? `${items.label} is a required field.` : false }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                sx={{ flex: 1 }}
                autoComplete="off"
                {...field}
                value={field.value ?? ''}
                error={error ? true : false}
                helperText={error ? error.message : ''}
                {..._props}
                disabled={disabled}
              />
            )}
          />
        )
      }
      else if (type === 'MODEL') {
        return (
          <Controller
            control={control}
            name={items.key}
            rules={{ required: items.required &&  items.required === true? `${items.label} is a required field.` : false }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                sx={{ flex: 1 }}
                autoComplete="off"
                {...field}
                value={field.value ?? ''}
                error={error ? true : false}
                helperText={error ? error.message : ''}
                {..._props}
                disabled={disabled}
              />
            )}
          />
        )
      }
      else if (type === 'LIST_GROUP') {
        return (
          <Controller
            control={control}
            name={items.key}
            rules={{ required: items.required &&  items.required === true? `${items.label} is a required field.` : false }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                sx={{ flex: 1 }}
                autoComplete="off"
                {...field}
                value={field.value ?? ''}
                error={error ? true : false}
                helperText={error ? error.message : ''}
                {..._props}
                disabled={disabled}
              />
            )}
          />
        )
      }
      else if (type === 'UUID' || type === 'SEQUENCE') {
        return (
          <Controller
            control={control}
            name={items.key}
            // rules={{ required: `${items.label} is required.` }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                sx={{ flex: 1 }}
                autoComplete="off"
                {...field}
                value={mode === FORM_MODE.EDIT ? field.value ?? '' : '[Auto]'}
                error={error ? true : false}
                helperText={error ? error.message : ''}
                {..._props}
                disabled={disabled}
              />
            )}
          />
        )
      }
      else if (type === 'TEXT') {
        const CustomComponent = items.component && CustomFormComponents[items.component] ? CustomFormComponents[items.component] : TextField
        const componentProps = items.componentProps && items.componentProps.length > 0 ? JSON.parse(items.componentProps) : {}

        return (
          <Controller
            control={control}
            name={items.key ?? items.code}
            rules={{ required: items.required &&  items.required === true? `${items.label} is a required field.` : false }}
            render={({ field: {onBlur, onChange, value}, fieldState: { error } }) => (
              <CustomComponent
                sx={{ flex: 1 }}
                rows={4}
                onBlur={onBlur}
                onChange={onChange}
                value={value ?? ''}
                error={error ? true : false}
                helperText={error ? error.message : ''}
                {..._props}
                {...componentProps}
                disabled={disabled}
                multiline
              />
            )}
          />
        )
      }
      else if (type === 'INTEGER') {
        return (
          <Controller
            control={control}
            name={items.key ?? items.code}
            rules={{ required: items.required &&  items.required === true? `${items.label} is a required field.` : false }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                sx={{ flex: 1 }}
                {...field}
                value={field.value ?? ''}
                error={error ? true : false}
                helperText={error ? error.message : ''}
                autoComplete="off"
                // inputRef={ref}
                {..._props}
                disabled={disabled}
                type="number"
              />
            )}
          />
        )
      }
      else if (type === 'DECIMAL' || type === 'MONEY') {
        return (
          <Controller
            control={control}
            name={items.key ?? items.code}
            rules={{ required: items.required &&  items.required === true? `${items.label} is a required field.` : false }}
            render={({ field: {onBlur, onChange, value}, fieldState: { error } }) => (
              <TextField
                sx={{ flex: 1 }}
                // {...field}
                onBlur={onBlur}
                onChange={onChange}
                value={value ?? ''}
                error={error ? true : false}
                helperText={error ? error.message : ''}
                autoComplete="off"
                // inputRef={ref}
                {..._props}
                disabled={disabled}
                type="number"
              />
            )}
          />
        )
      }
      else if (type === 'DATE') {
        return (
          <Controller
            control={control}
            name={items.key ?? items.code}
            rules={{ required: items.required &&  items.required === true? `${items.label} is a required field.` : false }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                sx={{ flex: 1 }}
                {...field}
                value={field.value ?? ''}
                error={error ? true : false}
                helperText={error ? error.message : ''}
                autoComplete="off"
                // inputRef={ref}
                {..._props}
                disabled={disabled}
                type="date"
              />
            )}
          />
        )
      }
      else if (type === 'BOOLEAN') {
        return (
          <Controller
            control={control}
            name={items.key ?? items.code}
            // rules={{ required: items.required &&  items.required === true? `${items.label} is a required field.` : false }}
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                {..._props}
                sx={{ flex: 1 }}
                control={
                  <Switch
                    {...field}
                    checked={field.value ?? false}
                    disabled={disabled}
                  />}
                labelPlacement="top"
              />
            )}
          />
        )
      }
    }
  }
}

export default FormComponent;