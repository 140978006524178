import { DATA_TYPE } from './constants'

const updateFormWithAttribute = ({ data, attributes }) => {
  if (data.properties) {
    const _properties = data.properties.map((property) => {
      return updateFormWithAttribute({ data: property, attributes })
    })

    return { ...data, properties: _properties }
  }
  else if (typeof data.type === 'undefined') {
    const _attr = attributes.find((attr) => attr.code === data)

    return {
      key: _attr.code,
      type: _attr.type,
      label: _attr.name,
      required: _attr.required,
      ...(_attr.isKey === true && { isKey: true }),
      ...(_attr.isLabel === true && { isLabel: true }),
      ...(_attr.isSystem === true && { isSystem: true }),
      ...(_attr.readOnly === true && { readOnly: true }),
      ...(_attr.defaultValue && { defaultValue: _attr.defaultValue }),
      ...(_attr.component && { component: _attr.component }),
      ...(_attr.componentProps && { componentProps: _attr.componentProps }),
    }
  }
  else if (data.type === 'FIELD') {
    const _attr = attributes.find((attr) => attr.code === data.code)

    return {
      key: _attr.code,
      type: _attr.type,
      label: _attr.name,
      required: _attr.required,
      ...(_attr.isKey === true && { isKey: true }),
      ...(_attr.isLabel === true && { isLabel: true }),
      ...(_attr.isSystem === true && { isSystem: true }),
      ...(_attr.readOnly === true && { readOnly: true }),
      ...(_attr.defaultValue && { defaultValue: _attr.defaultValue }),
      ...(_attr.component && { component: _attr.component }),
      ...(_attr.componentProps && { componentProps: _attr.componentProps }),
      ...data
    }
  }
}

const updateViewWithAttribute = ({ data, attributes }) => {
  if (data.properties) {
    const _properties = data.properties.map((property) => {
      if (typeof property === 'string') {
        const attr = attributes.find((attr) => attr.code === property)
        return { ...attr }
      }

      const attr = attributes.find((attr) => attr.code === property.code)
      return { ...property, ...attr }
    })

    return { ...data, properties: _properties }
  }

  return null
}

const getInitialDefaultValue = (attribute) => {
  if (attribute.type === DATA_TYPE.BOOLEAN) {
    return (typeof attribute.defaultValue === 'boolean' && attribute.defaultValue && attribute.defaultValue === true) || (typeof attribute.defaultValue === 'string' && attribute.defaultValue && attribute.defaultValue.toUpperCase() === 'Y') ? true : false
  }
  else {
    if (attribute.required || attribute.defaultValue)
      return attribute.defaultValue ?? ''
  }

  return null
}

const getGraphqlValue = ({value, type}) => {
  if (type === DATA_TYPE.INTEGER || type === DATA_TYPE.SEQUENCE) {
    return value.length === 0? null : parseInt(value)
  }
  else {
    return value
  }
}

export {
  updateFormWithAttribute,
  updateViewWithAttribute,
  getInitialDefaultValue,
  getGraphqlValue
}