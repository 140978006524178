import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { useStoreState } from '../common/storeContext'

import {
  Box,
  Link,
  Breadcrumbs,
} from '@mui/material';

const initialBreadcrumbData = [{ code: '/', name: 'Home', href: '/' }]

const ActiveLastBreadcrumb = () => {
  const appState = useStoreState()
  const navigate = useNavigate()

  const params = useParams()
  const [items, setItems] = useState([])

  const handleClickLink = (href) => {
    navigate(href)
  }

  useEffect(() => {
    const { model, modelId } = params
    const _items = [...initialBreadcrumbData]

    if (model) {
      const _model = appState.schema[model]
      _items.push({ code: model, name: _model?.name ?? '', href: `/${model}` })
    }

    if (modelId) {
      _items.push({ code: modelId, name: modelId, href: `/${model}/${modelId}` })
    }

    setItems(_items)
  }, [params, appState.schema])

  return (
    <Box role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        {items.map((item, index) => {
          return (
            <Link
              key={item.code}
              underline="hover"
              color={index === (item.length - 1) ? "text.primary" : "inherit"}
              aria-current={index === (item.length - 1) ? "page" : "false"}
              onClick={() => { handleClickLink(item.href) }}
            >{item.name}</Link>
          )
        })}
      </Breadcrumbs>
    </Box>
  );
}

export default ActiveLastBreadcrumb