import React, { useReducer, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { useForm, FormProvider } from "react-hook-form"

import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from '@mui/material';

import {
  Save as SaveIcon,
  Refresh as RefreshIcon,
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../common/storeContext'
import { useMiddletier } from '../common/middletier'
import * as Utils from '../common/utils'
import { DATA_TYPE_TO_GRAPHQL } from '../common/constants'

import ActiveLastBreadcrumb from '../components/activeLastBreadcrumb'
import FormComponent from '../components/formComponent'

const FORM_ACTION = {
  RESET: 'RESET',
  SET_MODEL: 'SET_MODEL',
  SET_FORM_PROPERTIES: 'SET_FORM_PROPERTIES',
  SET_VALUES: 'SET_VALUES',
  SET_REFRESH: 'SET_REFRESH',
  SET_MODEL_ID: 'SET_MODEL_ID',
  SET_FORM_MODE: 'SET_FORM_MODE'
}

const FORM_MODE = {
  NEW: 'NEW',
  EDIT: 'EDIT',
  READ_ONLY: 'READ_ONLY'
}

const initialFormData = {
  model: {},
  modelId: '',
  formProperties: [],
  refresh: false,
  defaultValues: {},
  values: {},
  mode: FORM_MODE.NEW
}

const formReducer = (state, action) => {
  switch (action.type) {
    case FORM_ACTION.RESET: {
      return { ...initialFormData };
    }

    case FORM_ACTION.SET_MODEL: {
      const _model = { ...action.payload }

      const defaultValues = _model.attributes.reduce((result, attribute) => {
        result[attribute.code] = Utils.getInitialDefaultValue(attribute)
        return result
      }, {})

      // console.log('_model.attributes: ', _model.attributes, defaultValues)
      return { ...state, model: _model, formProperties: [..._model.forms[0].properties], defaultValues };
    }

    case FORM_ACTION.SET_MODEL_ID: {
      return { ...state, modelId: action.payload };
    }

    case FORM_ACTION.SET_FORM_PROPERTIES: {
      return { ...state, formProperties: [...action.payload.properties] };
    }

    case FORM_ACTION.SET_REFRESH: {
      return { ...state, refresh: action.payload ?? false };
    }

    case FORM_ACTION.SET_VALUES: {
      const _values = { ...action.payload }
      return { ...state, values: _values };
    }

    case FORM_ACTION.SET_FORM_MODE: {
      return { ...state, mode: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const DefaultForm = (props) => {
  const appState = useStoreState()
  const appDispatch = useStoreDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const methods = useForm()
  const { reset, getValues, formState: { isDirty, dirtyFields }, handleSubmit } = methods
  const { query, mutation } = useMiddletier()

  const [formState, formDispatch] = useReducer(formReducer, initialFormData)

  const onValidationFail = errors => console.log(errors);

  const { sx } = props

  const onSave = (values) => {
    // const values = getValues()
    const method = formState.mode === FORM_MODE.EDIT ? formState.model.update : formState.model.create
    let params = []

    if (formState.mode === FORM_MODE.EDIT) {
      params = Object.keys(dirtyFields).reduce((result, key) => {
        const attribute = formState.model.attributes.find((attr) => attr.code === key)
        result.push({ code: key, graphqlType: DATA_TYPE_TO_GRAPHQL[attribute.type], required: attribute.required, value: values[key] })
        return result
      }, [
        { ...formState.model.isKey, value: values[formState.model.isKey.code] },
        { code: 'update_ctr', graphqlType: 'Int', required: true, value: values['update_ctr'] }
      ])
    }
    else {
      params = Object.keys(dirtyFields).reduce((result, key) => {
        const attribute = formState.model.attributes.find((attr) => attr.code === key)
        result.push({
          code: key,
          graphqlType: DATA_TYPE_TO_GRAPHQL[attribute.type],
          required: attribute.required,
          value: Utils.getGraphqlValue({ value: values[key], type: attribute.type })
        })
        return result
      },
        []
      )

      params = formState.model.attributes.reduce((result, attr) => {
        if ((typeof (attr.isSystem) === 'undefined' || attr.isSystem === false) && (attr.required && attr.required === true) && attr.defaultValue) {
          if (params.findIndex((param) => param.code === attr.code) === -1) {
            result.push({
              code: attr.code,
              graphqlType: DATA_TYPE_TO_GRAPHQL[attr.type],
              required: attr.required,
              value: Utils.getGraphqlValue({ value: attr.defaultValue, type: attr.type })
            })
          }
        }
        return result
      }, [...params])
    }

    mutation([
      {
        method,
        params,
        attributes: formState.model.properties ?? []
      }
    ])
      .then(({ data }) => {
        navigate(`/${formState.model.id}/${data[method][formState.model.isKey.code]}`)
      })
      .catch((error) => {
        console.error(error)
        // formDispatch({ type: FORM_ACTION.SET_REFRESH, payload: false })
        if (error.graphQLErrors) {
          error.graphQLErrors.forEach((_error) => {
            if (_error.extensions.code === 'UNAUTHENTICATED') {
              appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            }
          })
        }
      })
  }

  const handleClickDelete = () => {
    const values = getValues()
    const id = formState.model.isKey.code
    const method = formState.model.delete
    const params = [
      { ...formState.model.isKey, value: Utils.getGraphqlValue({ value: values[id], type: formState.model.isKey.type }) },
      { code: 'update_ctr', graphqlType: 'Int', required: true, value: values['update_ctr'] }
    ]

    mutation([{ method, params }])
      .then(({ data }) => {
        navigate(`/${formState.model.id}/new`)
      })
      .catch((error) => {
        console.error(error)
        // formDispatch({ type: FORM_ACTION.SET_REFRESH, payload: false })
        if (error.graphQLErrors) {
          error.graphQLErrors.forEach((_error) => {
            if (_error.extensions.code === 'UNAUTHENTICATED') {
              appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            }
          })
        }
      })
  }

  const handleClickRefresh = () => {
    const values = getValues()
    const id = formState.model.isKey.code
    const method = formState.model.findOne
    const params = [
      { ...formState.model.isKey, value: Utils.getGraphqlValue({ value: values[id], type: formState.model.isKey.type }) }
    ]
    const attributes = [...formState.model.properties]

    query({ method, params, attributes })
      .then(({ data }) => {
        reset({ ...data[method] })
      })
      .catch((error) => {
        console.error(error)
        // formDispatch({ type: FORM_ACTION.SET_REFRESH, payload: false })
        if (error.graphQLErrors) {
          error.graphQLErrors.forEach((_error) => {
            if (_error.extensions.code === 'UNAUTHENTICATED') {
              appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            }
          })
        }
      })
  }

  useEffect(() => {
    const { model, modelId } = params
    const _model = appState.schema[model]

    if (_model && modelId) {
      formDispatch({ type: FORM_ACTION.SET_MODEL, payload: _model })
      formDispatch({ type: FORM_ACTION.SET_MODEL_ID, payload: modelId })
      formDispatch({ type: FORM_ACTION.SET_FORM_MODE, payload: FORM_MODE.EDIT })
      formDispatch({ type: FORM_ACTION.SET_REFRESH, payload: true })
    }
    else if (_model) {
      formDispatch({ type: FORM_ACTION.SET_MODEL, payload: _model })
      formDispatch({ type: FORM_ACTION.SET_MODEL_ID, payload: null })
      formDispatch({ type: FORM_ACTION.SET_FORM_MODE, payload: FORM_MODE.NEW })
      const defaultValues = _model.attributes.reduce((result, attribute) => {
        result[attribute.code] = Utils.getInitialDefaultValue(attribute)
        return result
      }, {})
      reset({ ...defaultValues })
    }
    else {
      formDispatch({ type: FORM_ACTION.RESET })
    }
  }, [appState, params, reset])

  // useEffect(() => {
  //   // reset({ ...formState.defaultValues })
  // }, [reset, formState.defaultValues])

  useEffect(() => {
    if (formState.refresh === true) {
      const method = formState.model.findOne
      const params = [
        { ...formState.model.isKey, value: Utils.getGraphqlValue({ value: formState.modelId, type: formState.model.isKey.type }) },
      ]
      const attributes = [...formState.model.properties]

      query({
        method,
        params,
        attributes
      })
        .then(({ data }) => {
          formDispatch({ type: FORM_ACTION.SET_REFRESH, payload: false })
          reset({ ...data[method] })
        })
        .catch((error) => {
          console.error(error)
          formDispatch({ type: FORM_ACTION.SET_REFRESH, payload: false })
          if (error.graphQLErrors) {
            error.graphQLErrors.forEach((_error) => {
              if (_error.extensions.code === 'UNAUTHENTICATED') {
                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
              }
            })
          }
        })
    }
  }, [formState.model, formState.modelId, formState.refresh, query, reset, appDispatch])

  useEffect(() => {
    if (appState.loggedIn === false) {
      navigate('/login')
    }
  }, [appState, navigate])

  return (
    <Box sx={{ ...sx, flexDirection: 'column', gap: '5px' }}>
      <ActiveLastBreadcrumb />
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '5px' }}>
        <Box sx={{ backgroundColor: '#fff' }}>
          <Toolbar variant='dense'>
            <Typography variant="h6" sx={{ flex: 1 }} >{formState.modelId ?? '[New]'}</Typography>
            <Button variant="text" startIcon={<SaveIcon />} disabled={isDirty === false} onClick={handleSubmit(onSave, onValidationFail)}>Save</Button>
            <Button variant="text" startIcon={<DeleteIcon />} disabled={formState.modelId ? false : true} onClick={handleClickDelete}>Delete</Button>
            <Button variant="text" startIcon={<RefreshIcon />} disabled={formState.modelId ? false : true} onClick={handleClickRefresh}>Refresh</Button>
            <IconButton aria-label="more action">
              <MoreVertIcon />
            </IconButton>
          </Toolbar>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', backgroundColor: '#fff', padding: '5px', gap: '5px', position: 'relative' }}>
          <FormProvider {...methods} >
            <FormComponent mode={formState.mode} items={formState.formProperties ?? []} />
          </FormProvider>
        </Box>
      </Box>
    </Box>
  )
}

export default DefaultForm