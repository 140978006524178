import React, { useReducer, useContext, useEffect, createContext } from 'react'
import * as Utils from './utils'

const APP_STORE_ACTION = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  TOGGLE_NAV_BAR: 'TOGGLE_NAV_BAR',
  SET_ONLINE: 'SET_ONLINE',
  SET_SELECTED_NAV_ITEM: 'SET_SELECTED_NAV_ITEM',
  SHOW_ALERT: 'SHOW_ALERT',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
}

const StoreStateContext = createContext()
const StoreDispatchContext = createContext()

const initialState = {
  loggedIn: false,
  online: true,
  showNav: true,
  selectedNavitem: '/',
  navItems: [
    { code: '/', name: 'Dashboard', icon: 'Dashboard' },
  ],
  schema: [],
  alert: {
    open: false,
    title: '',
    description: '',
    forwardUrl: '',
  },
};

// const updateFormWithAttribute = ({ data, attributes }) => {
//   if (data.properties) {
//     const _properties = data.properties.map((property) => {
//       return updateFormWithAttribute({ data: property, attributes })
//     })

//     return { ...data, properties: _properties }
//   }
//   else if (typeof data.type === 'undefined') {
//     const _attr = attributes.find((attr) => attr.code === data)

//     return {
//       key: _attr.code,
//       type: _attr.type,
//       label: _attr.name,
//       required: _attr.required,
//       ...(_attr.isKey === true && { isKey: true }),
//       ...(_attr.isLabel === true && { isLabel: true }),
//       ...(_attr.isSystem === true && { isSystem: true }),
//       ...(_attr.readOnly === true && { readOnly: true }),
//       ...(_attr.component && { component: _attr.component }),
//       ...(_attr.componentProps && { componentProps: _attr.componentProps }),
//     }
//   }
//   else if (data.type === 'FIELD') {
//     const _attr = attributes.find((attr) => attr.code === data.code)

//     return {
//       key: _attr.code,
//       type: _attr.type,
//       label: _attr.name,
//       required: _attr.required,
//       ...(_attr.isKey === true && { isKey: true }),
//       ...(_attr.isLabel === true && { isLabel: true }),
//       ...(_attr.isSystem === true && { isSystem: true }),
//       ...(_attr.readOnly === true && { readOnly: true }),
//       ...(_attr.component && { component: _attr.component }),
//       ...(_attr.componentProps && { componentProps: _attr.componentProps }),
//       ...data
//     }
//   }
// }

// const updateViewWithAttribute = ({ data, attributes }) => {
//   if (data.properties) {
//     const _properties = data.properties.map((property) => {
//       if (typeof property === 'string') {
//         const attr = attributes.find((attr) => attr.code === property)
//         return { ...attr }
//       }

//       const attr = attributes.find((attr) => attr.code === property.code)
//       return { ...property, ...attr }
//     })

//     return { ...data, properties: _properties }
//   }

//   return null
// }

function storeReducer(state, action) {
  switch (action.type) {
    case APP_STORE_ACTION.LOGIN: {
      const navItems = [
        { code: '/', name: 'Dashboard', icon: 'Dashboard' },
      ]

      const { schema: _schema } = action.payload
      const schema = Object.keys(_schema).reduce((result, key) => {
        const _model = _schema[key]

        navItems.push({ code: key, name: _model.name, icon: 'Dataset' })

        const properties = _model.attributes.map((attribute) => attribute.code)

        const forms = _model.forms.map((form) => {
          return Utils.updateFormWithAttribute({ data: form, attributes: _model.attributes })
        })

        const views = _model.views.map((view) => {
          return Utils.updateViewWithAttribute({ data: view, attributes: _model.attributes })
        })

        result[key] = { ..._model, forms, views, properties }
        return result
      }, {})

      return { ...state, loggedIn: true, alert: { open: false, title: '', description: '', forwardUrl: '', }, schema, navItems };
    }

    case APP_STORE_ACTION.LOGOUT:
      return { ...initialState };

    case APP_STORE_ACTION.TOGGLE_NAV_BAR:
      return { ...state, showNav: !state.showNav };

    case APP_STORE_ACTION.SET_ONLINE: {
      return { ...state, online: action.payload }
    }

    case APP_STORE_ACTION.SET_SELECTED_NAV_ITEM: {
      return { ...state, selectedNavitem: action.payload }
    }

    case APP_STORE_ACTION.SHOW_ALERT: {
      if (typeof action.payload === 'boolean' && action.payload === false) {
        return { ...state, alert: { open: false, title: '', description: '', forwardUrl: '' } }
      }

      return { ...state, alert: { open: true, title: action.payload.title ?? '', description: action.payload.description ?? '' } }
    }

    case APP_STORE_ACTION.UNAUTHENTICATED: {
      return { ...state, alert: { type: APP_STORE_ACTION.UNAUTHENTICATED, open: true, title: 'No Access Token', description: 'Please login.' } }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(storeReducer, initialState)

  useEffect(() => {
    const controller = new AbortController();

    const _onOnlineStatus = () => {
      dispatch({ type: APP_STORE_ACTION.SET_ONLINE, payload: navigator.onLine })
    }

    dispatch({ type: APP_STORE_ACTION.SET_ONLINE, payload: navigator.onLine })

    window.addEventListener('offline', _onOnlineStatus, { signal: controller.signal });
    window.addEventListener('online', _onOnlineStatus, { signal: controller.signal });

    return () => {
      controller.abort();
    }
  }, [])

  return (
    <StoreStateContext.Provider value={state}>
      <StoreDispatchContext.Provider value={dispatch}>
        {children}
      </StoreDispatchContext.Provider>
    </StoreStateContext.Provider>
  )
}

function useStoreState() {
  const context = useContext(StoreStateContext)
  if (context === undefined) {
    throw new Error('useStoreState must be used within a StoreProvider')
  }
  return context
}

function useStoreDispatch() {
  const context = useContext(StoreDispatchContext)
  if (context === undefined) {
    throw new Error('useStoreDispatch must be used within a StoreProvider')
  }
  return context
}

export { StoreProvider, useStoreState, useStoreDispatch, APP_STORE_ACTION }