import React, { useEffect, useState, useReducer, useCallback } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../common/storeContext'
import { useMiddletier } from '../common/middletier'
import * as Utils from '../common/utils'
import ActiveLastBreadcrumb from '../components/activeLastBreadcrumb'

import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Link,
} from '@mui/material';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Refresh as RefreshIcon,
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'

const NavLink = (props) => {
  const navigate = useNavigate()
  const { href } = props;

  const handleClick = () => {
    navigate(href ?? '/')
  }

  return <Link variant="body2" onClick={handleClick}>{props.value}</Link>
}

const VIEW_ACTION = {
  RESET: 'reset',
  SET_ITEMS: 'setItems',
  SET_COLUMNS: 'setColumns',
  SET_MODEL: 'setModel',
  SET_REFRESH: 'setRefresh',
}

const initialViewData = {
  model: {},
  columns: [],
  items: [],
  selectedItems: [],
  refresh: false,
  properties: []
}

const viewReducer = (state, action) => {
  switch (action.type) {
    case VIEW_ACTION.RESET: {
      return { ...initialViewData };
    }

    case VIEW_ACTION.SET_MODEL: {
      return { ...state, model: { ...action.payload }, properties: [...action.payload.views[0].properties] };
    }

    case VIEW_ACTION.SET_ITEMS: {
      return { ...state, items: [...action.payload] };
    }

    case VIEW_ACTION.SET_COLUMNS: {
      return { ...state, columns: [...action.payload] };
    }

    case VIEW_ACTION.SET_REFRESH: {
      return { ...state, refresh: action.payload ?? false };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const DATA_TYPE_TO_VIEW_COLUMN = {
  'STRING': 'string',
  'UUID': 'string',
  'TEXT': 'string',
  'BOOLEAN': 'boolean',
  'INTEGER': 'number',
  'SEQUENCE': 'number',
  'DECIMAL': 'number',
  'MONEY': 'number',
  'DATETIME': 'dateTime',
  'DATE': 'date'
}

const DefaultView = (props) => {
  const appState = useStoreState()
  const appDispatch = useStoreDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const { query, mutation } = useMiddletier()

  const [selectionModel, setSelectionModel] = useState([])
  const [viewState, viewDispatch] = useReducer(viewReducer, initialViewData)

  const { sx } = props

  const handleClickEdit = useCallback(
    (model, row) => {
      navigate(`/${model.id}/${row.id}`)
    },
    [navigate],
  );

  const handleClickNew = () => {
    navigate(`/${viewState.model.id}/new`)
  }

  const handleClickDelete = () => {
    const items = [...viewState.items]
    const method = viewState.model.delete
    const id = viewState.model.isKey.code
    const _mutations = selectionModel.reduce((result, selection) => {
      const item = items.find((item) => item[id] === selection)

      if (item) {
        result.push({
          index: `${method}_${result.length}`,
          method,
          params: [
            { ...viewState.model.isKey, value: Utils.getGraphqlValue({ value: item[id], type: viewState.model.isKey.type }) },
            { code: 'update_ctr', graphqlType: 'Int', required: true, value: item['update_ctr'] }
          ]
        })
      }

      return result
    }, [])

    mutation([..._mutations])
      .then(({ data }) => {
        viewDispatch({ type: VIEW_ACTION.SET_REFRESH, payload: true })
      })
      .catch((error) => {
        console.error(error)
        // formDispatch({ type: FORM_ACTION.SET_REFRESH, payload: false })
        if (error.graphQLErrors) {
          error.graphQLErrors.forEach((_error) => {
            if (_error.extensions.code === 'UNAUTHENTICATED') {
              appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            }
          })
        }
      })
  }

  const handleClickRefresh = () => {
    viewDispatch({ type: VIEW_ACTION.SET_REFRESH, payload: true })
  }

  useEffect(() => {
    if (appState.loggedIn === false) {
      navigate('/login')
    }
  }, [appState.loggedIn, navigate])

  useEffect(() => {
    const { model } = params
    const _model = appState.schema[model]

    if (_model) {
      viewDispatch({ type: VIEW_ACTION.SET_MODEL, payload: _model })
      viewDispatch({ type: VIEW_ACTION.SET_REFRESH, payload: true })
    }
    else {
      viewDispatch({ type: VIEW_ACTION.RESET })
    }
  }, [params, appState.schema])

  useEffect(() => {
    const model = viewState.model

    if (model && viewState.properties && viewState.properties.length > 0) {
      const columns = viewState.properties.map((column) => {
        const _column = { width: 120, ...column, field: column.code, headerName: column.name, type: DATA_TYPE_TO_VIEW_COLUMN[column.type] }
        if (column.isKey) {
          _column['renderCell'] = ((model) => {
            return (params) => {
              return <NavLink value={params.value} href={`/${model.id}/${params.value}`} />
            }
          })(model)
        }
        return _column
      })

      columns.push({
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            onClick={((model) => {
              return () => {
                return handleClickEdit(model, params.row)
              }
            })(model)}
            label="Edit"
          />,
          // <GridActionsCellItem icon={...} onClick={...} label="Print" showInMenu />,
        ]
      })

      viewDispatch({ type: VIEW_ACTION.SET_COLUMNS, payload: columns })
    }
    else {
      viewDispatch({ type: VIEW_ACTION.SET_COLUMNS, payload: [] })
    }
  }, [viewState.properties, viewState.model, handleClickEdit])

  useEffect(() => {
    if (viewState.refresh === true) {
      const method = viewState.model.findAll
      const params = [
        { code: 'filter', graphqlType: 'JSON', required: true, value: {} },
      ]
      const attributes = viewState.model.properties
      
      query({
        method,
        params,
        attributes
      })
        .then(({ data }) => {
          viewDispatch({ type: VIEW_ACTION.SET_REFRESH, payload: false })
          viewDispatch({ type: VIEW_ACTION.SET_ITEMS, payload: data[method] })
        })
        .catch((error) => {
          console.error(error)
          viewDispatch({ type: VIEW_ACTION.SET_REFRESH, payload: false })
          if (error.graphQLErrors) {
            error.graphQLErrors.forEach((_error) => {
              if (_error.extensions.code === 'UNAUTHENTICATED') {
                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
              }
            })
          }
        })
    }
  }, [viewState.refresh, viewState.model, query, appDispatch])

  return (
    <Box sx={{ ...sx, flexDirection: 'column', gap: '5px' }}>
      <ActiveLastBreadcrumb />
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '5px' }}>
        <Box sx={{ backgroundColor: '#fff' }}>
          <Toolbar variant='dense'>
            <Typography variant="h6" sx={{ flex: 1 }} >{viewState.model.name ?? ''}</Typography>
            <Button variant="text" startIcon={<AddIcon />} onClick={handleClickNew}>New</Button>
            <Button variant="text" startIcon={<DeleteIcon />} disabled={selectionModel.length === 0} onClick={handleClickDelete}>Delete</Button>
            <Button variant="text" startIcon={<RefreshIcon />} onClick={handleClickRefresh}>Refresh</Button>
            <IconButton aria-label="more action">
              <MoreVertIcon />
            </IconButton>
          </Toolbar>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', backgroundColor: '#fff' }}>
          <DataGrid
            disableColumnFilter
            density={"compact"}
            rows={viewState.items}
            columns={viewState.columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default DefaultView