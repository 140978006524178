
import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom"

import {
  Box,
} from '@mui/material';

import { useStoreState } from '../common/storeContext'

const Dashboard = (props) => {
  const appState = useStoreState()
  const navigate = useNavigate()

  const { sx } = props

  useEffect(() => {
    if (appState.loggedIn === false) {
      navigate('/login')
    }
  }, [appState.loggedIn, navigate])

  return <Box sx={{ ...sx }}>Dashboard</Box>
}

export default Dashboard